import React from "react";
import ElasticDevs from "../../Assets/Svgs/elasticdevs.svg";
import Remove from "../../Assets/Svgs/remove.svg";
import { Link } from "react-router-dom";
// import ContactButton from "../Buttons/ContactButton";
// import ContactUsButton from "../Buttons/ContactUsButton";
import { HashLink } from "react-router-hash-link";
import { MVPlink } from "./MVPlink";
const Sidebar = ({ handleHeader }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yoffset = -50;
    window.scrollTo({ top: yCoordinate + yoffset, behavior: "smooth" });
  };
  return (
    <>
      <MVPlink />
      <div
        className=" ps-5 py-[15px] absolute w-screen bg-[#FFFFFF]
    pb-[600px] overflow-y-hidden duration-700">
        <div className="flex justify-between items-center ">
          <div>
            <Link to="/">
              {" "}
              <img
                className="w-28 md:w-36"
                onClick={handleHeader}
                src={ElasticDevs}
                alt="elasticdevs"
              />
            </Link>
          </div>
          <div onClick={handleHeader} className="cursor-pointer me-7 ">
            <img src={Remove} alt="Remove" />
          </div>
        </div>
        <div className="mt-3">
          <div className="flex flex-col space-y-6  text-sm font-normal">
            <HashLink
              to="/#Home"
              onClick={handleHeader}
              className="mt-3"
              scroll={(el) => scrollWithOffset(el)}
              smooth>
              <a href="Home">Home</a>
            </HashLink>
            <HashLink
              to="/#Services"
              smooth
              className="mt-3"
              scroll={(el) => scrollWithOffset(el)}
              onClick={handleHeader}>
              <a href="Services"> Services</a>
            </HashLink>
            <Link to="/blockchainServices">
              {" "}
              <a
                href="blockchainServices"
                className="mt-3"
                onClick={handleHeader}>
                {" "}
                Blockchain Services
              </a>
            </Link>
            <HashLink
              to="/#Expertise"
              smooth
              className="mt-3"
              scroll={(el) => scrollWithOffset(el)}
              onClick={handleHeader}>
              <a href="Expertise">Expertise</a>
            </HashLink>
            <HashLink
              to="/#WhyElasticdevs"
              scroll={(el) => scrollWithOffset(el)}
              smooth
              className="mt-3"
              onClick={handleHeader}>
              <a href="WhyUs">Why Us</a>
            </HashLink>
            <HashLink
              to="/#How we work"
              scroll={(el) => scrollWithOffset(el)}
              smooth
              className="mt-3"
              onClick={handleHeader}>
              <a href="Work">How We Work</a>
            </HashLink>
            <Link to="/story">
              {" "}
              <a href="story" className="mt-3" onClick={handleHeader}>
                Our Story
              </a>
            </Link>
            <Link to="/culture">
              {" "}
              <a href="culture" className="mt-3" onClick={handleHeader}>
                Our Culture
              </a>
            </Link>
            <Link to="/careers">
              {" "}
              <a href="careers" className="mt-3" onClick={handleHeader}>
                {" "}
                Careers
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
