import React from "react";
import ElasticDevs from "../../Assets/Svgs/elasticFooter.svg";
import Phone from "../../Assets/Svgs/phone.svg";
import Mail from "../../Assets/Svgs/mail.svg";
import Location from "../../Assets/Svgs/location.svg";
import FooterForm from "./FooterForm";
import Facebook from "../../Assets/Svgs/facebook.svg";
import Twitter from "../../Assets/Svgs/twitter.svg";
import Linkedin from "../../Assets/Svgs/linkedin.svg";
import Instagram from "../../Assets/Svgs/instagram.svg";
import Youtube from "../../Assets/Svgs/youtube.svg";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const FooterMobile = () => {
  const currentYear = new Date().getFullYear();
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yoffset = -50;
    window.scrollTo({ top: yCoordinate + yoffset, behavior: "smooth" });
  };
  return (
    <div
      className="px-5 py-4 text-[#ffffff] bg-[#2B2927] md:hidden"
      id="ContactMobile">
      <div className="py-10">
        <Link to="/">
          {" "}
          <img
            className="w-28 "
            loading="lazy"
            src={ElasticDevs}
            alt="elasticdevs"
          />
        </Link>
      </div>
      <div className="flex gap-6">
        <a
          href="https://m.facebook.com/profile.php/?id=100095292707048&name=xhp_nt__fb__action__open_user"
          target="_blank"
          rel="noreferrer">
          <img className="w-6" src={Facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/elasticdevs/"
          target="_blank"
          rel="noreferrer">
          <img className="w-6" src={Instagram} alt="Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/company/elasticdevs"
          target="_blank"
          rel="noreferrer">
          <img className="w-6" src={Linkedin} alt="Linkedin" />
        </a>
        <a
          href="https://www.twitter.com/elasticdevs"
          target="_blank"
          rel="noreferrer">
          <img className="w-6" src={Twitter} alt="Twitter" />
        </a>
        <a
          href="https://www.youtube.com/@ElasticDevsOfficial"
          target="_blank"
          rel="noreferrer">
          <img className="w-8" src={Youtube} alt="Youtube" />
        </a>
      </div>
      <div className="mt-10">
        <hr className="mb-14  border-t-[1px] border-zinc-500 " />
        <p className="font-normal">Let’s take your</p>{" "}
        <p className="text-[#FEB800] text-xl font-medium">
          development to the next level
        </p>
      </div>
      <div className="mt-3 mb-16">
        <FooterForm />
      </div>
      <div className="mt-10 mb-6">
        <hr className=" border-t-[1px] border-zinc-500 " />
      </div>

      <div className="flex flex-col text-xs font-normal">
        <HashLink
          className="mb-4"
          to="/#Home"
          scroll={(el) => scrollWithOffset(el)}
          smooth>
          {" "}
          <a href="Home">Home</a>
        </HashLink>
        <HashLink
          className="mb-4"
          to="/#Services"
          smooth
          scroll={(el) => scrollWithOffset(el)}>
          {" "}
          <a href="Services"> Services</a>
        </HashLink>

        <HashLink
          className="mb-4"
          to="/#Expertise"
          smooth
          scroll={(el) => scrollWithOffset(el)}>
          {" "}
          <a href="Expertise">Expertise</a>
        </HashLink>

        <HashLink
          to="/#WhyElasticdevs"
          className="mb-4"
          scroll={(el) => scrollWithOffset(el)}
          smooth>
          {" "}
          <a href="WhyUs">Why Us</a>
        </HashLink>

        <HashLink
          to="/#How we work"
          className="mb-4"
          scroll={(el) => scrollWithOffset(el)}
          smooth>
          {" "}
          <a href="Work">How We Work</a>
        </HashLink>
        <Link to="/careers" className="mb-4">
          <a href="careers">Careers</a>
        </Link>
        <Link to="/culture" className="mb-4">
          <a href="culture">Our Culture</a>
        </Link>
        <Link to="/story" className="mb-4">
          <a href="story">Our Story</a>
        </Link>
        <Link to="/privacy" className="mb-4">
          <a href="privacy">Privacy Policy</a>
        </Link>
      </div>
      {/* <div className="mt-14">
        <p className="font-normal">Let’s take your</p>{" "}
        <p className="text-[#FEB800] text-xl font-medium">
          development to next level
        </p>
      </div>
      <div className="mt-14">
        <FooterForm />
      </div> */}
      <div className="pb-10 pt-3">
        <hr className=" border-t-[1px] border-zinc-500 " />
      </div>
      <div className="text-xs mt-4 pb-8 font-semibold">
        <div className="flex">
          <img className="w-4 me-2 d" loading="lazy" src={Phone} alt="Phone" />
          <span>+91 879 229 1300 </span>
        </div>
        <div className="flex my-5">
          <img className="w-4 me-2" loading="lazy" src={Mail} alt="Mail" />
          <span>info@elasticdevs.io</span>
        </div>
        <div className="flex">
          <img
            className="w-4 me-2 "
            loading="lazy"
            src={Location}
            alt="Location"
          />
          <span>2343, HSR Layout, 17th cross road, AJ Forte, Near Gayatri Temple, BANGLORE, Karnataka 560102</span>
        </div>
      </div>
      <p className="text-[8px] text-center">
        &copy; Elastic Devs {currentYear} All Rights Reserved
      </p>
    </div>
  );
};

export default FooterMobile;
