import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import Footer from "../Footer/Footer";
import FooterMobile from "../Footer/FooterMobile";
import LOGO from "../../Assets/Svgs/LOGO.svg";
const Loading = () => {
  return (
    <>
      <HeaderComponent />
      <div className="flex justify-center items-center h-[80%]">
        <img className="lg:w-20" src={LOGO} alt="logo" />
      </div>
      <Footer />
      <FooterMobile />
    </>
  );
};

export default Loading;
