import React from "react";
import FooterContact from "./FooterContact";
import FooterDevelopment from "./FooterDevelopment";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterNavbar from "./FooterNavbar";

const Footer = () => {
  return (
    <div className=" bg-[#2B2927]">
      {" "}
      <div
        className="hidden md:block md:px-10 xl:px-14 text-[#FFFFFF] bg-[#2B2927] lg:py-[100px]  3xl:max-w-[1940px] 3xl:m-auto"
        id="Contact">
        <div>
          <FooterContact />
        </div>
        <div className="py-10">
          <hr />
        </div>
        <div>
          <FooterDevelopment />
        </div>
        <div className="py-5">
          <FooterSocialMedia />
        </div>
        <div className="py-6 lg:pt-6 lg:py-0">
          <FooterNavbar />
        </div>
      </div>
    </div>
  );
};

export default Footer;
