import React from "react";
import FooterForm from "./FooterForm";

const FooterDevelopment = () => {
  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-5">
      <div className="lg:col-span-2 md:col-span-3">
        <p className="font-normal 2xl:text-[24px]">Let’s take your</p>{" "}
        <p className="text-[#FEB800] text-xl xl:text-2xl 2xl:text-[36px] font-medium">
          Development to the next level
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2  ">
        <FooterForm />
      </div>
    </div>
  );
};

export default FooterDevelopment;
