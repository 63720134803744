import React, { useState } from "react";

import { HashLink } from "react-router-hash-link";
import Arrow from "../../Assets/Svgs/AboutUsArrow.svg";
const OurServices = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yoffset = -50;
    window.scrollTo({ top: yCoordinate + yoffset, behavior: "smooth" });
  };
  return (
    <div
      className="relative inline-block text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <button
        type="button"
        className="text-black focus:outline-none rounded-lg md:text-xs  4xl:text-[18px]  font-medium
         text-center inline-flex items-center">
        Our Services
        <img src={Arrow} alt="arrow" className=" ml-2" />
      </button>

      {isHovered && (
        <div className="pt-1 2xl:pt-2 origin-top-right absolute right-0 md:w-[115px] 4xl:w-[150px]   rounded-lg shadow-md bg-white divide-y divide-gray-100 dark:bg-gray-700">
          <ul className="py-2 text-[10px] xl:text-xs 4xl:text-sm text-gray-700 dark:text-gray-200">
            <li>
              <HashLink
                to="/#Services"
                smooth
                scroll={(el) => scrollWithOffset(el)}>
                <a
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  href="Services">
                  Services
                </a>
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#Expertise"
                smooth
                scroll={(el) => scrollWithOffset(el)}>
                <a
                  href="Expertise"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  Expertise
                </a>
              </HashLink>
            </li>

            <li>
              {" "}
              <HashLink
                to="/#WhyElasticdevs"
                scroll={(el) => scrollWithOffset(el)}
                smooth>
                <a
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  href="WhyUs">
                  Why Us
                </a>
              </HashLink>
            </li>
            <li>
              {" "}
              <HashLink
                to="/#How we work"
                scroll={(el) => scrollWithOffset(el)}
                smooth>
                <a
                  href="Work"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  How We Work
                </a>
              </HashLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default OurServices;
