import React from "react";
import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

const HeaderComponent = () => {
  let [flag, setFlag] = useState(true);
  const handleHeader = () => {
    setFlag(!flag);
  };
  return (
    <div className="sticky top-0 z-50">
      {flag ? (
        <div className="duration-700">
          <Header handleHeader={handleHeader} />
        </div>
      ) : (
        <div className="fixed w-full top-0 duration-700">
          <Sidebar handleHeader={handleHeader} />
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
