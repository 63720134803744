import React from "react";
import Star from "../../Assets/Gifs/Star.gif";
import { Link } from "react-router-dom";
export const MVPlink = () => {
  return (
    <div className="bg-[#FE423F] font-semibold  xl:hidden cursor-pointer duration-700">
      <Link to="/MVP">
        {" "}
        <div className="flex justify-center items-center  py-[6px]">
          <div>
            <img className="w-5" src={Star} alt="star" />
          </div>
          <div className="text-[11px] md:text-[14px] text-white">
            Build your MVP in one month
          </div>
          <div>
            <img className="w-5" src={Star} alt="star" />
          </div>
        </div>
      </Link>
    </div>
  );
};
