import React from "react";
import ElasticDevs from "../../Assets/Svgs/elasticdevs.svg";
import Menu from "../../Assets/Svgs/menu.svg";
import Chat from "../../Assets/Svgs/chat.svg";
import PhoneHeader from "../../Assets/Svgs/phoneHeader.svg";
import ContactButton from "../Buttons/ContactButton";
import ContactUsButton from "../Buttons/ContactUsButton";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import AboutUs from "./AboutUs";
import OurServices from "./OurServices";
import OurProducts from "./OurProducts";
import { MVPlink } from "./MVPlink";
const Header = ({ handleHeader }) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yoffset = -50;
    window.scrollTo({ top: yCoordinate + yoffset, behavior: "smooth" });
  };

  return (
    <>
      <MVPlink />
      <div className="bg-[#FFFFFF] ">
        {" "}
        <div
          className="bg-[#FFFFFF]  px-5 md:px-10 xl:px-14
    flex justify-between py-3 xl:py-[30px]  items-center 3xl:max-w-[1940px] 3xl:m-auto">
          <div className="flex flex-col ">
            <div>
              {" "}
              <Link to="/">
                {" "}
                <img
                  className="w-28 md:w-36 xl:w-40 2xl:w-44"
                  src={ElasticDevs}
                  alt="elasticdevs"
                />
              </Link>
            </div>

            <div className=" place-self-end xl:mt-[10px] mt-[8px] hidden xl:block">
              <Link to="/MVP">
                <div
                  className="text-white italic bg-[#FE423F] py-[3px] px-[8px] xl:py-[5px] xl:px-[10px] rounded-[6px] inline
               xl:text-[12px] text-[10px] font-bold leading-none">
                  Launch MVP
                </div>{" "}
              </Link>
            </div>
          </div>
          <section className="xl:hidden flex gap-5 md:gap-7 items-center justify-center">
            <HashLink
              to="/#ContactMobile"
              scroll={(el) => scrollWithOffset(el)}
              smooth>
              <img className="w-4" src={Chat} alt="chat" />
            </HashLink>
            <HashLink
              to="/#Consultation"
              scroll={(el) => scrollWithOffset(el)}
              smooth>
              <img className="w-4" src={PhoneHeader} alt="phone" />
            </HashLink>
            <img
              className="cursor-pointer"
              onClick={handleHeader}
              src={Menu}
              alt="menu"
            />
          </section>
          <section
            className="hidden xl:block md:text-xs  4xl:text-[18px]  md:pt-1
      font-medium ">
            <HashLink to="/#Home" scroll={(el) => scrollWithOffset(el)} smooth>
              <a className="" href="Home">
                Home
              </a>
            </HashLink>
            <Link to="/MVP">
              {" "}
              <span className="text-[#FE423F] md:ms-3 lg:ms-4 2xl:ms-7">
                Launch MVP
              </span>
            </Link>
            <Link to="/blockchainServices">
              {" "}
              <span className="text-[#FE423F] md:ms-3 lg:ms-4 2xl:ms-7">
                Blockchain Services
              </span>
            </Link>
            <span className="md:ms-3 lg:ms-4 2xl:ms-7 relative">
              {" "}
              <OurServices />
            </span>
            <span className="md:ms-3 lg:ms-4 2xl:ms-7 relative">
              {" "}
              <OurProducts />
            </span>
            <span className="md:ms-3 lg:ms-4 2xl:ms-7 relative">
              {" "}
              <AboutUs />
            </span>
            <HashLink
              to="/#Contact"
              scroll={(el) => scrollWithOffset(el)}
              smooth>
              <a className="md:mx-3 lg:mx-4 2xl:mx-7" href="contact">
                <ContactUsButton button="Contact Us" />
              </a>
            </HashLink>
            <HashLink
              to="/#Consultation"
              scroll={(el) => scrollWithOffset(el)}
              smooth>
              <a href="contact">
                <ContactButton button="Free Consultation Call" />
              </a>
            </HashLink>
          </section>
        </div>
      </div>
    </>
  );
};
export default Header;
