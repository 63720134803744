import "./index.css";
import React from "react";
// import HomePage from "./Routes/HomePage";
// import PrivacyPolicy from "./Routes/PrivacyPolicy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./Components/Fillers/Loading";
import ScrollToTop from "./ScrollToTop";
// import Careers from "./Routes/Careers";
// import JobDescription from "./Components/Careers/JobDescription";
// import OurStory from "./Routes/OurStory";
// import Culture from "./Routes/Culture";
const HomePage = React.lazy(() => import("./Routes/HomePage"));
const Culture = React.lazy(() => import("./Routes/Culture"));
const OurStory = React.lazy(() => import("./Routes/OurStory"));
const Careers = React.lazy(() => import("./Routes/Careers"));
const PrivacyPolicy = React.lazy(() => import("./Routes/PrivacyPolicy"));
const JobDescription = React.lazy(() =>
  import("./Components/Careers/JobDescription")
);
const MVP = React.lazy(() => import("./Routes/MVP"));
const BlockchainServices = React.lazy(() =>
  import("./Routes/BlockchainServices")
);
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <HomePage />
              </React.Suspense>
            }></Route>
          <Route
            path="/privacy"
            element={
              <React.Suspense fallback={<Loading />}>
                <PrivacyPolicy />
              </React.Suspense>
            }></Route>
          <Route
            path="/careers"
            element={
              <React.Suspense fallback={<Loading />}>
                <Careers />
              </React.Suspense>
            }></Route>
          <Route
            path="/careers/JD"
            element={
              <React.Suspense fallback={<Loading />}>
                <JobDescription />
              </React.Suspense>
            }></Route>
          <Route
            path="/story"
            element={
              <React.Suspense fallback={<Loading />}>
                <OurStory />
              </React.Suspense>
            }
          />
          <Route
            path="/culture"
            element={
              <React.Suspense fallback={<Loading />}>
                <Culture />
              </React.Suspense>
            }></Route>
          <Route
            path="/MVP"
            element={
              <React.Suspense fallback={<Loading />}>
                <MVP />
              </React.Suspense>
            }></Route>
          <Route
            path="/blockchainServices"
            element={
              <React.Suspense fallback={<Loading />}>
                <BlockchainServices />
              </React.Suspense>
            }></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
