import React, { useState, useEffect } from "react";
import ContactButton from "../Buttons/ContactButton";
import Validation from "./Validation";
import axios from "axios";
import ConfirmCard from "../../Assets/Svgs/Confirmation.svg";
import "./Footer.css";
const FooterForm = () => {
  const initialState = {
    floating_first_name: "",
    floating_email: "",
    floating_phone: "",
    floating_company: "",
  };

  const [user, setUser] = useState(initialState);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const [flag, setFlag] = useState(true); // Initialize to true

  useEffect(() => {
    // Check for errors whenever the errors object changes
    const hasErrors = Object.keys(errors).length > 0;
    setFlag(!hasErrors); // Set flag to true if there are no errors
  }, [errors]);

  const changeHandler = ({ target }) => {
    setUser((prevUser) => ({
      ...prevUser,
      [target.name]: target.value,
    }));

    // Validate the field and update errors
    const fieldErrors = Validation({ ...user, [target.name]: target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [target.name]: fieldErrors[target.name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the entire form before submitting
    const formErrors = Validation(user);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      // No errors, proceed with submission
      setSending(true);

      const requestData = {
        username: user.floating_first_name,
        email: user.floating_email,
        contact: user.floating_phone,
        message: user.floating_company,
      };

      try {
        const response = await axios.post("api/users/contactus", requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setResponse(response.data);
        setUser(initialState);
        setErrors({});
      } catch (error) {
        // Handle submission error
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <>
      {response && <img src={ConfirmCard} alt="confirm" />}

      {!response && (
        <div className="bg-[#3E3B38] p-2 lg:p-5 rounded-xl">
          <form>
            <>
              <div className="relative z-0 w-full pb-3 group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  className="block py-2.5  xl:py-3.5  px-0 w-full text-sm text-[#ffffff] bg-transparent 
          border-0 border-b-[1px] border-gray-300 appearance-none  focus:outline-none inputMVP
          focus:ring-0 focus:border-[#FEB800] peer"
                  placeholder=" "
                  value={user.floating_first_name}
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                />
                <label
                  for="floating_first_name"
                  className="peer-focus:font-medium absolute 2xl:text-[18px] text-xs text-[#ffffff] 
          duration-300 transform -translate-y-6 scale-75 top-5 -z-10 origin-[0]
          peer-focus:left-0 peer-focus:text-[#FEB800]  peer-focus:dark:text-[#FEB800] 
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
          peer-focus:scale-75 peer-focus:-translate-y-6">
                  {!errors.floating_first_name && <span>Name*</span>}
                  <span className="text-[#E03570]">
                    {errors.floating_first_name}
                  </span>
                </label>
              </div>
              <div className="relative z-0 w-full pb-3 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5  xl:py-3.5 px-0 w-full text-sm text-[#ffffff] bg-transparent 
          border-0 border-b-[1px]  border-gray-300  appearance-none  focus:outline-none inputMVP
          focus:ring-0 focus:border-[#FEB800]  peer"
                  placeholder=" "
                  value={user.floating_email}
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                />
                <label
                  for="floating_email"
                  className="peer-focus:font-medium 2xl:text-[18px] absolute text-xs text-[#ffffff] 
          duration-300 transform -translate-y-6 scale-75 top-5 -z-10 origin-[0]
          peer-focus:left-0 peer-focus:text-[#FEB800]  peer-focus:dark:text-[#FEB800] 
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
          peer-focus:scale-75 peer-focus:-translate-y-6">
                  {!errors.floating_email && <span>Email*</span>}
                  <span className="text-[#E03570]">
                    {errors.floating_email}
                  </span>
                </label>
              </div>{" "}
              <div className="relative z-0 w-full pb-3 group">
                <input
                  type="text"
                  name="floating_phone"
                  id="floating_phone"
                  className="block py-2.5 xl:py-3.5  px-0 w-full  text-sm text-[#ffffff] bg-transparent 
          border-0 border-b-[1px] border-gray-300 appearance-none  focus:outline-none 
          focus:ring-0 focus:border-[#FEB800]  peer inputMVP"
                  placeholder=" "
                  value={user.floating_phone}
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                />
                <label
                  for="floating_phone"
                  className="peer-focus:font-medium 2xl:text-[18px] absolute text-xs text-[#ffffff] 
          duration-300 transform -translate-y-6 scale-75 top-5 -z-10 origin-[0]
          peer-focus:left-0 peer-focus:text-[#FEB800]  peer-focus:dark:text-[#FEB800] 
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
          peer-focus:scale-75 peer-focus:-translate-y-6">
                  {!errors.floating_phone && <span>Mobile Number*</span>}
                  <span className="text-[#E03570]">
                    {errors.floating_phone}
                  </span>
                </label>
              </div>
              <div className="relative z-0 w-full group">
                <input
                  type="text"
                  name="floating_company"
                  id="floating_company"
                  className="block py-2.5  xl:py-3.5 px-0 w-full text-sm text-[#ffffff] bg-transparent 
          border-0 border-b-[1px]  border-gray-300  appearance-none  focus:outline-none 
          focus:ring-0 focus:border-[#FEB800]  peer inputMVP"
                  placeholder=" "
                  value={user.floating_company}
                  onChange={(e) => {
                    changeHandler(e);
                  }}
                />
                <label
                  for="floating_company"
                  className="peer-focus:font-medium 2xl:text-[18px] absolute text-xs text-[#ffffff] 
          duration-300 transform -translate-y-6 scale-75 top-5 -z-10 origin-[0]
          peer-focus:left-0 peer-focus:text-[#FEB800]  peer-focus:dark:text-[#FEB800] 
          peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
          peer-focus:scale-75 peer-focus:-translate-y-6">
                  {!errors.floating_company && (
                    <span> How we can help you?*</span>
                  )}
                  <span className="text-[#E03570]">
                    {errors.floating_company}
                  </span>
                </label>
              </div>
            </>
            <div className="pt-5 mt-5">
              {!sending && (
                <ContactButton
                  button="Send"
                  handleSubmit={handleSubmit}
                  // isSubmitting={isSubmitting}
                />
              )}
              {sending && (
                <p className="text-xs xl:text-sm text-[#FEB800]  text-center">
                  Sending...
                </p>
              )}
            </div>
          </form>
        </div>
      )}

      <p className="hidden">{flag}</p>
    </>
  );
};

export default FooterForm;
