import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const FooterNavbar = () => {
  const currentYear = new Date().getFullYear();
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yoffset = -50;
    window.scrollTo({ top: yCoordinate + yoffset, behavior: "smooth" });
  };
  return (
    <div className="grid lg:grid-cols-7 md:grid-cols-5 text-xs xl:text-sm 2xl:text-[18px]">
      <div className="lg:col-span-3 md:col-span-1">
        <Link to="/privacy">
          <a href="/privacy">Privacy Policy</a>
        </Link>
        <p className="inline xl:ms-6 2xl:ms-8">
          &copy; Elastic Devs {currentYear} All Rights Reserved
        </p>
      </div>

      <div className="lg:col-span-4 md:col-span-4 flex justify-between">
        <HashLink to="/#Home" scroll={(el) => scrollWithOffset(el)} smooth>
          {" "}
          <a href="Home">Home</a>
        </HashLink>

        <HashLink to="/#Services" smooth scroll={(el) => scrollWithOffset(el)}>
          {" "}
          <a href="Services"> Services</a>
        </HashLink>

        <HashLink to="/#Expertise" smooth scroll={(el) => scrollWithOffset(el)}>
          <a href="Expertise">Expertise</a>
        </HashLink>

        <HashLink
          to="/#WhyElasticdevs"
          scroll={(el) => scrollWithOffset(el)}
          smooth>
          <a href="WhyUs">Why Us</a>
        </HashLink>

        <HashLink
          to="/#How we work"
          scroll={(el) => scrollWithOffset(el)}
          smooth>
          <a href="Work">How We Work</a>
        </HashLink>
        <Link to="/story">
          <a href="story">Our Story</a>
        </Link>
        <Link to="/culture">
          <a href="culture">Our Culture</a>
        </Link>
        <Link to="/careers">
          <a href="careers">Careers</a>
        </Link>
      </div>
    </div>
  );
};

export default FooterNavbar;
