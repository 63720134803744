const Validation = (user) => {
  const newErrors = {};

  function isValidEmail(email) {
    const blockedProviders = [
      "gmail.com",
      "yahoo.com",
      "outlook.com",
      "aol.com",
      "icloud.com",
      "zoho.com",

      "mailinator.com",
      "addy.com",
      "temp-mail.org",
      "guerrillamail.com",
      "10minutemail.net",
      "dispostable.com",
      "throwawaymail.com",
      "getnada.com",
      "jetable.org",
      "tempmail.net",
      "gmx.com",
      "protonmail.com",
    ];
    const domain = email.split("@")[1];
    // console.log(domain);
    return blockedProviders.includes(domain);
  }

  if (!user.floating_first_name.trim()) {
    newErrors.floating_first_name = "*Name is required.";
  }

  if (!user.floating_phone.trim()) {
    newErrors.floating_phone = "*Contact number is required.";
  } else if (!/^[+0-9\-() ]*$/.test(user.floating_phone)) {
    newErrors.floating_phone = "*Invalid contact number.";
  }

  if (!user.floating_email.trim()) {
    newErrors.floating_email = "*Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(user.floating_email)) {
    newErrors.floating_email = "*Invalid email format";
  } else if (isValidEmail(user.floating_email)) {
    newErrors.floating_email = "*Enter Business email address";
  }

  if (!user.floating_company.trim()) {
    newErrors.floating_company = "*Please tell us about your query.";
  }

  return newErrors;
};

export default Validation;
