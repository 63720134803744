import React from "react";
import SocialMedia from "../../Assets/SpriteSvgs/SocialMedia.svg";
const FooterSocialMedia = () => {
  return (
    <div className="grid lg:grid-cols-10 md:grid-cols-8 gap-3">
      <div className="flex justify-between lg:col-span-2 md:col-span-3">
        <a
          href="https://m.facebook.com/profile.php/?id=100095292707048&name=xhp_nt__fb__action__open_user"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-[30px] ">
          <svg>
            <use xlinkHref={`${SocialMedia}#facebook`} />
          </svg>
        </a>
        <a
          href="https://www.instagram.com/elasticdevs/"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-[30px]">
          <svg>
            <use xlinkHref={`${SocialMedia}#instagram`} />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/elasticdevs"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-[30px]">
          <svg>
            <use xlinkHref={`${SocialMedia}#linkedin`} />
          </svg>
        </a>
        <a
          href="https://www.twitter.com/elasticdevs"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-[30px]">
          {" "}
          <svg>
            <use xlinkHref={`${SocialMedia}#twitter`} />
          </svg>
        </a>
        <a
          href="https://www.youtube.com/@ElasticDevsOfficial"
          target="_blank"
          rel="noreferrer"
          className="w-10 h-[30px]">
          <svg>
            <use xlinkHref={`${SocialMedia}#youtube`} />
          </svg>
        </a>
      </div>
      <div className="lg:col-span-8 md:col-span-5">
        <hr className="mt-4" />
      </div>
    </div>
  );
};

export default FooterSocialMedia;
