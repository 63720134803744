import React from "react";
import Arrow from "../../Assets/Svgs/arrow.svg";
import Arrow1 from "../../Assets/Svgs/arrowBlack.svg";
const ContactButton = ({ button, handleSubmit }) => {
  // console.log(button, handleSubmit, isSubmitting);
  return (
    <span
      className="group text-[#FFFFFF] bg-[#26509B]  font-medium hover:bg-[#FEB800] transition ease-in-out  delay-10 
hover:text-black duration-300 w-fit
 rounded-lg md:py-2 md:px-4 xl:py-2.5 xl:px-5 py-2.5 px-6 sm:py-2 sm:px-4 text-xs xl:text-sm 2xl:text-[18px]">
      <button onClick={handleSubmit}>
        {" "}
        <img
          src={Arrow}
          alt="arrow"
          className="inline md:w-4 xl:w-5  me-2 pb-0.5 group-hover:hidden"
        />
        {button}
        <img
          src={Arrow1}
          alt="arrow"
          className="hidden md:w-4 xl:w-5   ms-2 pb-0.5  group-hover:inline group-hover:transition
          group-hover:delay-1000"
        />
      </button>
    </span>
  );
};

export default ContactButton;
