import React from "react";
import Contact from "../../Assets/Svgs/contact.svg";
import Arrow1 from "../../Assets/Svgs/arrowBlack.svg";
const ContactUsButton = ({ button }) => {
  return (
    <span
      className="group text-black border-[1px] border-black font-medium hover:border-transparent
      hover:bg-[#FEB800] transition ease-in-out  delay-10 hover:text-black duration-300 
 rounded-lg md:py-2 md:px-4 xl:py-2.5 xl:px-5 py-2 px-4 text-xs xl:text-sm w-fit 2xl:text-[18px]">
      <img
        src={Contact}
        alt="contact"
        className="inline md:w-4 xl:w-5  me-2 pb-0.5 group-hover:hidden"
      />

      <button>{button}</button>
      <img
        src={Arrow1}
        alt="arrow"
        className="hidden md:w-4 xl:w-5   ms-2 pb-0.5  group-hover:inline group-hover:transition
          group-hover:delay-1000"
      />
    </span>
  );
};
export default ContactUsButton;
