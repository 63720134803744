import React from "react";
import ElasticDevs from "../../Assets/Svgs/elasticFooter.svg";
import Phone from "../../Assets/Svgs/phone.svg";
import Mail from "../../Assets/Svgs/mail.svg";
import Location from "../../Assets/Svgs/location.svg";
import { Link } from "react-router-dom";
const FooterContact = () => {
  return (
    <div className="flex justify-between pt-10 lg:pt-0 font-semibold">
      <div>
        <Link to="/">
          {" "}
          <img
            loading="lazy"
            className="w-28 md:w-36 xl:w-40 2xl:w-44"
            src={ElasticDevs}
            alt="elasticdevs"
          />
        </Link>
      </div>
      <div className="md:text-xs xl:text-[14px] 2xl:text-[18px]">
        <div className="flex justify-between  ">
          <div className="flex">
            <img loading="lazy" className="w-4 me-2 " src={Phone} alt="Phone" />
            <span>+91 879 229 1300 </span>
          </div>
          <div className="flex ">
            <img loading="lazy" className="w-4 me-2" src={Mail} alt="Mail" />
            <span>info@elasticdevs.io</span>
          </div>
        </div>

        <div className="flex mt-5 justify-center items-center">
          <div> <img
            loading="lazy"
            className="w-4 me-2 "
            src={Location}
            alt="Location"
          /></div>
         
          <div>2343, HSR Layout, 17th cross road, AJ Forte, Near Gayatri Temple, BANGLORE, Karnataka 560102</div>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
